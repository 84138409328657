.claim-row {
    padding: 0 20px;
    height: 25px;
    background-color: #5FBC57;
    color: #fff;
    text-align: center;
    font-family: 'aBold', helvetica, arial;
    font-size: 13px;
    line-height: 25px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.headerTop {
	background-color: $primary-500;
	color: #fff;
	@include bold-font;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	height: 28px;
	line-height: 28px;
	overflow: hidden;
	@media screen and (min-width: 768px){
	  font-size: 13px;
	}
}
.carousel-banner {
    width: 400px;
    margin: 0 auto;
    transform: translateX(20px);
    .slider {
      .slide {
        padding: 0;
        text-align: left;
        @media screen and (min-width: 768px){
          text-align: left;
        }
      }
    }
  }


header {
	background-color: #fff;
	position: fixed;
	top: 0;
    width: 100%;
	z-index: 99;
	// height: 65px;
	-webkit-box-shadow: 0px 3px 3px 0px rgba(50, 50, 50, 0.2);
	-moz-box-shadow:    0px 3px 3px 0px rgba(50, 50, 50, 0.2);
	box-shadow:         0px 3px 3px 0px rgba(50, 50, 50, 0.2);
	img.logo {
		max-width: 190px;
		max-height: 65px;
		@media screen and (max-width: 500px){
			max-width: 170px;
			max-height: 60px;
		}
	}
	.btn-group {
		position: absolute;
		top: 18px;
		right: 18px;
		button {
			font-size: 0.8em;
			background-color: white;
			border: 0;
			color: $grey-400;
		}
	}
}



.lang {
	position: absolute;
	top: 42px;
	right: 5px;
}

/* Dropdown Button */
.dropbtn {
	background-color: #fff;
    color: #383d41;
    padding: 7px;
    font-size: 0.8em;
    border: none;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
	position: relative;
	display: inline-block;
	img {
		margin-left: 5px;
		width: 10px;
		margin-top: -3px;
	}
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
	display: none;
	position: absolute;
	background-color: #EFEFEF;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 1;
	right: 0;
	font-size: 0.8em;
	text-transform: uppercase;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #ddd;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {background-color: #EFEFEF;}

