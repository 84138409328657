// Variables

@import "bootstrap/scss/bootstrap.scss";


$purple-light: #D1A4C1;
$purple: #D180B5;
$purple-dark: #BF68A1;

// Primary
// $primary-900: ;
// $primary-800: ;
// $primary-700: ; 
// $primary-600: ;
$primary-500: #007067;
$primary-400: #16968F;
$primary-300: #64A9A5;
$primary-200: #95BFBD;
$primary-100: #C7DAD9;

// Neutral Greys A
$grey-900: #1D252C;
$grey-800: #3D4046;
$grey-700: #606066; 
$grey-600: #89888D;
$grey-500: #BBBABD;
// Neutral Greys B
$grey-400: #7B868C;
$grey-300: #91989E;
$grey-200: #A8ACB1;
$grey-100: #C1C3C6;
$grey-050: #DDDDDF;
$grey-000: #efefef;

// Secondary
$secondary-1: #2B9A79;
$secondary-2: #5FBC57;
$secondary-3: #BBB632;

$error-color: #e94643;
$success-color: #349899;
$state-success-text: $success-color;
$state-danger-text: $error-color;
$summaryGreen: #00878b;
$summaryGreenLight: #83db2f;
$summaryGray: #2f2f2f;
$confirmColor: $secondary-1;
$warning: orange;
$inactive: #cccccc;
$red: #f4909c;
$green: #6eb22c;



// Mixins
@mixin campos() {
	border: 3px solid black;
	text-transform: uppercase;
	font-size: 1.7rem;
	background: none;
	border-radius: 0 !important;

	// El color del placeholder que ya fue completado
	color: black;

	// El color del placeholder focus (cuando se escribe)
	&:focus {
		color: black !important;
	}
	&:focus::-webkit-input-placeholder {
	    color: black !important;
	}
	&:focus::-moz-placeholder {
	    color: black !important;
	}
	&:focus::-moz-placeholder {
	    color: black !important;
	}
	&:focus::-ms-input-placeholder {
	   color: black !important;
	}

	// El color del placeholder sin ser completado
	&::placeholder {
		color: black;
	}
	&::-webkit-input-placeholder {
		color: black;
	}
	&::-moz-placeholder {
		color: black;
	}
	&:-ms-input-placeholder{
		color: black;
	}

}

@mixin inputs() {
	@include ppal-font;
	height: 50px;
	text-align: center;

}


@mixin selects {
	appearance:none;
	-webkit-appearance: none;
	-moz-appearance:none;
	background-image: url(../images/icon-despliega.png);
	background-position:right 10px center;
	background-repeat:no-repeat;
}

@mixin ppal-font {
	font-family: 'aRegular', helvetica, arial;
}

@mixin demi-font {
	font-family: 'aDemi', helvetica, arial;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

@mixin bold-font {
	font-family: 'aBold', helvetica, arial;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

@font-face {
    font-family: 'aRegular';
    src: url('../fonts/AvenirNextLTProRegular.eot');
    src: url('../fonts/AvenirNextLTProRegular.eot') format('embedded-opentype'),
         url('../fonts/AvenirNextLTProRegular.woff2') format('woff2'),
         url('../fonts/AvenirNextLTProRegular.woff') format('woff'),
         url('../fonts/AvenirNextLTProRegular.ttf') format('truetype'),
         url('../fonts/AvenirNextLTProRegular.svg#AvenirNextLTProRegular') format('svg');
}

@font-face {
    font-family: 'aDemi';
    src: url('../fonts/AvenirNextLTProDemi.eot');
    src: url('../fonts/AvenirNextLTProDemi.eot') format('embedded-opentype'),
         url('../fonts/AvenirNextLTProDemi.woff2') format('woff2'),
         url('../fonts/AvenirNextLTProDemi.woff') format('woff'),
         url('../fonts/AvenirNextLTProDemi.ttf') format('truetype'),
         url('../fonts/AvenirNextLTProDemi.svg#AvenirNextLTProDemi') format('svg');
}

@font-face {
    font-family: 'aBold';
    src: url('../fonts/AvenirNextLTProBold.eot');
    src: url('../fonts/AvenirNextLTProBold.eot') format('embedded-opentype'),
         url('../fonts/AvenirNextLTProBold.woff2') format('woff2'),
         url('../fonts/AvenirNextLTProBold.woff') format('woff'),
         url('../fonts/AvenirNextLTProBold.ttf') format('truetype'),
         url('../fonts/AvenirNextLTProBold.svg#AvenirNextLTProBold') format('svg');
}

@font-face {
    font-family: 'aCond';
    src: url('../fonts/BebasNeue.eot');
    src: url('../fonts/BebasNeue.eot') format('embedded-opentype'),
         url('../fonts/BebasNeue.woff2') format('woff2'),
         url('../fonts/BebasNeue.woff') format('woff'),
         url('../fonts/BebasNeue.ttf') format('truetype'),
         url('../fonts/BebasNeue.svg#BebasNeue') format('svg');
}


body {
	@include ppal-font();
}

h1, h2, h3, h4 {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-weight: normal;
	@include bold-font();
	text-transform: uppercase;
}

h5, h6 {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-weight: normal;
}

h1 {font-size: 40px;}
h2 {font-size: 2.2em;}
h3 {font-size: 1.9em;}
h4 {font-size: 1.6em;}
h5 {font-size: 1.2em;}
h6 {font-size: 1em;}


p {
	font-size: 1em;
	b, strong {
		font-weight: normal;
	}
}


a {
	transition:all 0.2s ; 
	-moz-transition:all 0.2s; 
	-webkit-transition:all 0.2s;
	color: $grey-400;
	&:hover {
		transition:all 0.2s ; 
		-moz-transition:all 0.2s; 
		-webkit-transition:all 0.2s;
		color: $grey-900;
	}
	&:visited, &:focus  {
	}
}

ul {
	list-style:none;
	padding:0;
	margin:0;
}


label {
	font-weight: normal;
}

input, .form-control {
	@include campos;
	@include inputs;
}

input[type="submit"] {
	@include campos;
	@include inputs;
	&:hover {
	}
	&:visited, &:focus {
	}
}

textarea {
	@include campos;
}

button {
	background-color: black;
    border-color: black;
    text-transform: uppercase;
    border-radius: 0 !important;
    @include ppal-font;
}

.btn {

}


.container-match-result {
	margin-top: 93px;
	padding: 40px 0 0;
	background-color: rgb(25, 150, 146);
	background-image: url(../images/bg-club-public.jpg);
	background-position: top center;
	background-repeat: no-repeat;
	overflow: hidden;
}

.box-match {
	background-color: white;
	background-image: url(../images/bg-iplayme2-icon.svg);
	background-position: center bottom;
	background-repeat: no-repeat;
    width: 100%;
    max-width: 500px;
	border: 5px solid #82CBC9;
	margin-bottom: 10vh;
	border-radius: 10px;
}

h1.main-title {
	color: white;
	margin-top: 30px;
	margin-bottom: 5px;
}

.sport-pill {
	@include demi-font();
	color: #ece0ee;
	background-color: $purple;
	padding: 2px 18px 0 45px;
	height: 38px;
	line-height: 38px;
	border-radius: 20px;
	display: inline-block;
	position: relative;
	.icon-pill {
		position: absolute;
		left: 10px;
		top: 0;
		height: 38px;
	}
}

.match-info-wrapper {
	@include demi-font();
	color: white;
	font-size: 17px;
}


h1.title-box {
	background-color: $grey-900;
	color: $green;
	font-size: 1.2em;
}

.sub-title {
	color: white;
	img {
		max-width: 16px;
		max-height: 19px;
		margin-right: 3px;
	}
}


.ranking {
    padding: 10px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    /* border-radius: 20px; */
    border: 2px solid #d8dddf;
    height: 36px;
    padding: 0 3px 0 14px;
    text-transform: uppercase;
    font-size: 0.85em;
	@include demi-font();
    .valueP{
        margin-right: 10px;
        color: #5FBC57;
    }
    .valueN{
        margin-right: 10px;
        color: #DE4640;
    }
	.rating-pill {
		padding: 3px 11px;
		border-radius: 20px;
		color: white;
		&.up {
			background-color: #5FBC57;;
		}
		&.down {
			background-color: #DE4640;
		}
		img {
			margin-right: 3px;
		}
	}
}


.players {
	.player {
		margin: 0 8px ;
		&:nth-child(2) {
			margin-top: -6px;
		}
		.name {
			@include demi-font();
			font-size: 15px;
			@media (min-width: 992px) { 
				font-size: 16px;
			}
		}
	}
	.pic {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-size: cover;
		background-position: center;
		margin-right: 7px;
		@media (min-width: 992px) { 
			width: 45px;
			height: 45px;
		}
	}
	
}

.win {
	width: 24px;
	height: 24px;
	margin-left: 10px;
	img {
		width: 20px;
	}
}

.games {
	.game {
		width: 30px;
		height: 30px;
		margin: 0 1px;
		border-radius: 50%;
		background-color: $grey-400;
		color: white;
		position: relative;
		font-size: 16px;
		letter-spacing: -0.05em;
		@include demi-font();
		@media (min-width: 992px) { 
			width: 34px;
			height: 34px;
			margin: 0 4px;
			font-size: 16px;
		}
		span {
			position: absolute;
			top: -8px;
			right: -7px;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			background-color: $green;
			color: white;
			font-size: 12px;
			@include demi-font();
		}
	}
	.game-tbo {
		@extend .game;
		background-color: #008ded;
	}
}

.sets {
	background-color: #EDF3F6;
	color: $grey-400;
	text-transform: uppercase;
	font-size: 0.7em;
	position: relative;
	.set {
		width: 38px;
	}
	.word-set {
		position: absolute;
		left: 10px;
		top: 0;
		height: 35px;
	}
}

table {
	thead {
		color: $green;
		font-size: 0.8em;
		text-transform: uppercase;
		@include bold-font();
	}
}

table {
	th:first-child {
		text-align: left !important;
	}
}


.banner-download {
	background-color: $green;
	color: white;
	background-image: url(../images/back-banner-download.jpg);
	background-position: center;
	font-family: 'aCond', helvetica, arial;
	img {
		width: 135px;
		margin: 6px;
	}
	.title {
		font-size: 1.7em;
		letter-spacing: 0.02em;
	}
}


.social-menu {
	ul {
		li {
			margin: 10px;
			img {
				width: 40px;
			}
		}
	}
}

.languages {
	background-color: white;
}



// Footer

footer {
	background-color: $grey-900;
	color: #7B868C;
	p{
		font-size: 0.8em;
	}
}

.languages {
	ul {
		li {
			margin: 0 10px;
		}
	}
}


@import 'header';
@import 'downloadMaximaze';

/* Breakponits bootstrap */

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
}


/////////////////////////


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {  

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {  

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 

}


/////////////////////////


// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) { 
   
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) { 
   
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) { 
   
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
   
}



  