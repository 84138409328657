
.wrapper-banner-footer {
    width: 100%;
    background-color: $primary-500;
    background: rgb(69,125,129);
    background: linear-gradient(180deg, rgba(69,125,129,1) 0%, rgba(50,115,108,1) 100%);
    color:white;
    @media (min-width: 768px) and (max-width: 991px) { 
        width: 750px;
        margin: 0 auto 80px;
    }
    @media (min-width: 992px) { 
        width: 90%;
        max-width: 1500px;
        margin: 0 auto 80px;
    }
    .imgDownload {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center left;
        min-height: 300px;
        width: 100%;
        height: 100%;
        max-height: 100%;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .cover {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(238,183,244);
        background: linear-gradient(180deg, rgba(238,183,244,0.2) 0%, rgba(0,134,124,0.2) 100%);
    }
    .maximize-info {
        padding: 60px 5%;
        @media (min-width: 768px) and (max-width:1219px) {
            padding: 80px 8%;
        }
        @media (min-width: 1220px) {
            padding: 80px 12%;
        }
        .title{
            font-size: 37px;
            line-height: 1;
            margin: 0px 0 30px;
            @media (min-width: 992px) and (max-width:1349px) { 
                font-size: 44px;
                margin: 0px 0 18px;
            }
            @media (min-width: 1350px) { 
                font-size: 48px;
                margin: 0px 0 18px;
            }
        }
        .balls-title {
            width: 188px;
            margin-top: -10px;
            margin-left: 0px;
            @media (min-width: 768px) { 
                margin-top: -10px;
                margin-left: 9px;
            }
            @media (min-width: 1200px) { 
                width: 200px;
            }                
        }
        .text-box {
            font-size: 18px;
            @include demi-font;
            line-height: 1.7;
            margin-bottom: 50px;
            @media (min-width: 992px) { 
                margin-bottom: 50px;
            }
            .downloadText {
                text-decoration: none;
                color: #E6DD1C;
                padding-bottom: 3px;
                border-bottom: 1px solid #E6DD1C;
                margin-right: 5px;
                &:hover {
                    color: #454e4d;
                    border-bottom: 1px solid #454e4d;
                }
            }
        }
        .button-download {
            display: inline-block;
            color: white;
            background-color: $purple;    
            @include demi-font;
            font-size: 19px;
            border-radius: 50px;
            padding: 0 30px;
            text-decoration: none;
            height: 60px;
            line-height: 60px;
            img {
                width: 60px;
                margin-left: 15px;
            }
        }
        .free-forever {
            color:white;
            @include bold-font;
            font-size: 13px;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-top: 14px;
        }
    } 
}